import React from 'react';
import PropTypes from 'prop-types';
import { css, Global } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { Carousel } from '../Carousel';
import { t } from '../../../domain/services/configService';
import { repeat } from '../../../domain/utils/arrayUtils';
import { ParagraphWrapper } from '../ParagraphWrapper';

const PromoCarouselInner = ({ data, isAboveTheFold, type }) => {
  const typeConfig = {
    disney_plus: {
      background: '#054553',
      image: '/images/disney-plus.svg',
      label: 'Disney+',
      baseLiteral: '__DISNEY_PLUS_'
    },
    star_plus: {
      background:
        'radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(38, 30, 60, 1) 100%)',
      image: '/images/star-plus.svg',
      label: 'Star+',
      baseLiteral: '__STAR_PLUS_'
    }
  };

  const styles = {
    container: css`
      padding: 65px 0;
      background: ${typeConfig[type].background};
      text-align: center;
      margin-bottom: 65px;
    `,
    logo: css`
      display: block;
      margin: 0 auto;
      width: 145px;
      height: 79px;
    `,
    text: css`
      color: #fff;
      font-family: AvenirRoman, sans-serif;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    `,
    get header() {
      return css`
        ${styles.text};
        font-size: clamp(
          ${theme.font.size('xs2')},
          0.75vw,
          ${theme.font.size('s')}
        );
        margin: 15px 0 50px 0;
      `;
    },
    get cta() {
      return css`
        ${styles.text};
        font-size: ${theme.font.size('base')};
        display: inline-block;
        padding-bottom: 4px;
        border-bottom: 2px solid #fc0;
      `;
    },
    disclaimer: css`
      color: #fff;
      text-align: left;
      font-family: AvenirRoman, sans-serif;
      font-size: ${theme.font.size('xs2')};
      margin: 50px 0 0 20px;
    `
  };
  const carouselCards = repeat(data.cards, 4);
  const carouselData = {
    type: 'Carousel', // Used by ParagraphWrapper
    backgroundColor: 'transparent',
    fontColor: '#fff',
    carouselCard: JSON.parse(
      JSON.stringify(carouselCards.concat(carouselCards.slice(0, 4)))
    )
  };
  carouselData.carouselCard.forEach((card, index) => {
    carouselData.carouselCard[index].entity.openLinkInNewTab = true;
    carouselData.carouselCard[index].entity.key = `${card.entity.id}-${index}`;
  });

  return (
    <section css={styles.container}>
      <Global
        styles={[
          {
            '@font-face': {
              fontFamily: 'AvenirRoman',
              src: 'url("/fonts/AvenirRoman.woff2") format("woff2")',
              fontDisplay: 'swap'
            }
          }
        ]}
      />
      <img
        src={typeConfig[type].image}
        alt={typeConfig[type].label}
        css={styles.logo}
        loading={isAboveTheFold ? 'eager' : 'lazy'}
      />
      <p css={styles.header}>
        {data.header || t(`${typeConfig[type].baseLiteral}HEADER__`)}
      </p>

      <Carousel
        data={carouselData}
        aspect="4x5"
        transition={false}
        center
        scroll="item"
        cardClass="earthday_disneyplus"
        cardRel="noopener noreferrer nofollow"
        isAboveTheFold={isAboveTheFold}
      />

      <p>
        <a
          css={styles.cta}
          href={data.cta?.uri || t(`${typeConfig[type].baseLiteral}CTA_URI__`)}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          {data.cta?.title || t(`${typeConfig[type].baseLiteral}CTA_TITLE__`)}
        </a>
      </p>

      {data.hideLegalDisclaimerText ? null : (
        <p css={styles.disclaimer}>
          {data.legalDisclaimerText ||
            t(`${typeConfig[type].baseLiteral}LEGAL_DISCLAIMER_TEXT__`)}
        </p>
      )}
    </section>
  );
};
PromoCarouselInner.propTypes = {};

PromoCarouselInner.defaultProps = {
  isAboveTheFold: false,
  type: 'disney_plus'
};

PromoCarouselInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool,
  type: PropTypes.oneOf(['disney_plus', 'star_plus'])
};

export const PromoCarousel = ParagraphWrapper(PromoCarouselInner);
