const arrayIntersection = (arr1, arr2) => arr1.filter(x => arr2.includes(x));

const arrayRemoveByValue = (arr, value) => {
  const index = arr.indexOf(value);
  if (index !== -1) {
    arr.splice(index, 1);
  }
};

const primitiveOrArrayExec = (primitiveOrArray, arrayMethod, callback) => {
  if (Array.isArray(primitiveOrArray)) {
    return primitiveOrArray[arrayMethod](callback);
  }
  return callback(primitiveOrArray);
};

const sortAlphabetically = (arr, propertyName) => {
  arr.sort((a, b) => {
    if (a[propertyName] < b[propertyName]) {
      return -1;
    }
    if (a[propertyName] > b[propertyName]) {
      return 1;
    }
    return 0;
  });
};

const repeat = (arr, n) => {
  let out = [];
  for (let i = 0; i < n; i += 1) {
    out = out.concat(arr);
  }
  return out;
};

const getMiddleIndex = (arr, leaning = 'left') => {
  // Fix Math.round rounding to the next integer, which makes getting "3" for an array of 5 elements.
  // Thanks to this fix, the middle index of and array of 5 elements is "2".
  let round = Math.round(arr.length / 2);
  if (arr.length % 2 === 1) {
    round -= 1;
  }

  return round - (leaning === 'left' ? 1 : 0);
};

const moveElementToMiddle = (arr, index, leaning = 'left') => {
  const originalSize = arr.length;
  const originalMiddle = getMiddleIndex(arr, leaning);
  const tempArr = repeat(arr, 3);
  const sliceStart = originalSize + index - originalMiddle;
  return tempArr.slice(sliceStart, sliceStart + originalSize);
};

const chunkArray = (arr, n) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += n) {
    chunks.push(arr.slice(i, i + n));
  }
  return chunks;
};

module.exports = {
  arrayIntersection,
  arrayRemoveByValue,
  primitiveOrArrayExec,
  sortAlphabetically,
  repeat,
  getMiddleIndex,
  moveElementToMiddle,
  chunkArray
};
